import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import App from './App'; 
import ViewPack from './components/ViewPack'; 
import GenerateNotes from './components/GenerateNotes'; 
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/" element={<App />} /> {/* Route vers votre App */}
        <Route path="/view/:pack_id" element={<ViewPack />} /> {/* Route vers ViewPack */}
        <Route path="/generate/notes" element={<GenerateNotes />} /> {/* Route vers GenerateNotes */}
      </Routes>
    </Router>
  </React.StrictMode>
);

// Si vous voulez commencer à mesurer les performances de votre application, passez une fonction
// pour enregistrer les résultats (par exemple : reportWebVitals(console.log))
// ou envoyez à un point de terminaison d'analytique. En savoir plus : https://bit.ly/CRA-vitals
reportWebVitals();
