import React, { useEffect, useState } from 'react';
import { AppBar, Toolbar, Typography, Button, Box, CircularProgress, useMediaQuery, Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { supabase } from './supabase.js';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

let theme;

const websiteAnon = process.env.REACT_APP_WEBSITE_ANON;

switch (websiteAnon) {
    case '0':
      theme = createTheme({
        palette: {
          primary: {
            main: '#023047',
            light: '#FFF',
            dark: '#023047',
            contrastText: '#FFFFFF',
          },
          secondary: {
            main: '#FFB703',
            dark: '#FB8500',
            contrastText: '#023047',
          },
          background: {
            default: '#F5F5F5',
            paper: '#FFFFFF',
          },
          text: {
            primary: '#023047',
            secondary: '#023047',
          },
        },
        typography: {
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
          h4: {
            fontWeight: 600,
            color: '#023047',
          },
          h6: {
            fontWeight: 500,
          },
        },
        components: {
          MuiPaper: {
            styleOverrides: {
              root: {
                boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .1)',
              },
            },
          },
          MuiAppBar: {
            styleOverrides: {
              root: {
                backgroundColor: '#023047',
              },
            },
          },
          MuiCssBaseline: {
            styleOverrides: `
              .social-link {
                text-decoration: none; /* Supprime le soulignement */
                color: inherit;       /* Utilise la couleur du texte par défaut */
                transition: color 0.3s ease; /* Présente une transition douce */
              }
              .social-link:hover {
                color: #FFB703; /* Couleur de surbrillance au survol */
              }
            `,
          },
        },
      });
      break;
  
    case '1':
      theme = createTheme({
        palette: {
          primary: {
            main: '#000',
            light: '#FFF',
            dark: '#000',
            contrastText: '#FFFFFF',
          },
          secondary: {
            main: '#FFB703',
            dark: '#FB8500',
            contrastText: '#000',
          },
          background: {
            default: '#F5F5F5',
            paper: '#FFFFFF',
          },
          text: {
            primary: '#000',
            secondary: '#000',
          },
        },
        typography: {
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
          h4: {
            fontWeight: 600,
            color: '#000',
          },
          h6: {
            fontWeight: 500,
          },
        },
        components: {
          MuiPaper: {
            styleOverrides: {
              root: {
                boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .1)',
              },
            },
          },
          MuiAppBar: {
            styleOverrides: {
              root: {
                backgroundColor: '#000',
              },
            },
          },
          MuiCssBaseline: {
            styleOverrides: `
              .social-link {
                text-decoration: none; /* Supprime le soulignement */
                color: inherit;       /* Utilise la couleur du texte par défaut */
                transition: color 0.3s ease; /* Présente une transition douce */
              }
              .social-link:hover {
                color: #FFB703; /* Couleur de surbrillance au survol */
              }
            `,
          },
        },
      });
      break;
  
      case '2':
        theme = createTheme({
          palette: {
            primary: {
              main: '#000',
              light: '#FFF',
              dark: '#000',
              contrastText: '#FFFFFF',
            },
            secondary: {
              main: '#FFB703',
              dark: '#FB8500',
              contrastText: '#000',
            },
            background: {
              default: '#F5F5F5',
              paper: '#FFFFFF',
            },
            text: {
              primary: '#000',
              secondary: '#000',
            },
          },
          typography: {
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            h4: {
              fontWeight: 600,
              color: '#000',
            },
            h6: {
              fontWeight: 500,
            },
          },
          components: {
            MuiPaper: {
              styleOverrides: {
                root: {
                  boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .1)',
                },
              },
            },
            MuiAppBar: {
              styleOverrides: {
                root: {
                  backgroundColor: '#000',
                },
              },
            },
            MuiCssBaseline: {
              styleOverrides: `
                .social-link {
                  text-decoration: none; /* Supprime le soulignement */
                  color: inherit;       /* Utilise la couleur du texte par défaut */
                  transition: color 0.3s ease; /* Présente une transition douce */
                }
                .social-link:hover {
                  color: #FFB703; /* Couleur de surbrillance au survol */
                }
              `,
            },
          },
        });
        break;
  
    default:
      theme = createTheme({
        palette: {
          primary: {
            main: '#023047',
            light: '#FFF',
            dark: '#023047',
            contrastText: '#FFFFFF',
          },
          secondary: {
            main: '#FFB703',
            dark: '#FB8500',
            contrastText: '#023047',
          },
          background: {
            default: '#F5F5F5',
            paper: '#FFFFFF',
          },
          text: {
            primary: '#023047',
            secondary: '#023047',
          },
        },
        typography: {
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
          h4: {
            fontWeight: 600,
            color: '#023047',
          },
          h6: {
            fontWeight: 500,
          },
        },
        components: {
          MuiPaper: {
            styleOverrides: {
              root: {
                boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .1)',
              },
            },
          },
          MuiAppBar: {
            styleOverrides: {
              root: {
                backgroundColor: '#023047',
              },
            },
          },
          MuiCssBaseline: {
            styleOverrides: `
              .social-link {
                text-decoration: none; /* Supprime le soulignement */
                color: inherit;       /* Utilise la couleur du texte par défaut */
                transition: color 0.3s ease; /* Présente une transition douce */
              }
              .social-link:hover {
                color: #FFB703; /* Couleur de surbrillance au survol */
              }
            `,
          },
        },
      });
      break;
  }


const moisMapping = {
    '1': 'Janvier', '2': 'Février', '3': 'Mars', '4': 'Avril', '5': 'Mai',
    '6': 'Juin', '7': 'Juillet', '8': 'Août', '9': 'Septembre', '10': 'Octobre',
    '11': 'Novembre', '12': 'Décembre'
};

const situationMapping = {
    'C': 'Célibataire', 'M': 'Marié', 'D': 'Divorcé/Séparé', 'V': 'Veuf', 'O': 'Pacsé'
};

const GenerateNotes = () => {
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchpackData = async () => {
      try {
        // Récupérer les packs et leurs clients
        const { data: packData, error: packError } = await supabase
          .from('pack')
          .select(
            `
            *,
            customers (
              *,
              pay (
                mois,
                annee,
                salaire
              ),
              tax (
                revenu,
                annee_impots,
                situation,
                domiciliation,
                parts,
                autre_doc,
                pdf
              ),
              identity (
                id,
                type_doc,
                qualite,
                noiretblanc,
                pdf,
                birthdate,
                expiry_date,
                race,
                country,
                "4_bords_visibles",
                civilite,
                autre_doc
              )
            )
          `)
          .eq('statut', 'Actif')
          .order('id', { ascending: false })
    
        if (packError) {
          console.error('Erreur lors de la récupération des packs:', packError);
          setLoading(false);
          return;
        }
    
        // Récupérer les données des banques
        const { data: bankData, error: bankError } = await supabase
          .from('banque')
          .select('Code_Banque, Banque');
    
        if (bankError) {
          console.error('Erreur lors de la récupération des banques:', bankError);
          setLoading(false);
          return;
        }
    
        // Lier les noms de banque aux clients
        const updatedPackData = packData.map(pack => {
          const customersWithBanks = pack.customers.map(customer => {
            // Trouver la banque correspondante
            const bank = bankData.find(b => b.Code_Banque === customer.code_banque);
            // Retournons le client avec le nom de la banque
            return {
              ...customer,
              nom_banque: bank ? bank.Banque : 'N/A' // Ajout du nom de la banque
            };
          });
    
          return {
            ...pack,
            customers: customersWithBanks // Mettre à jour les clients avec les noms de banque
          };
        });
    
        setData(updatedPackData); // Mettre à jour l'état avec les données enrichies
        setLoading(false);
      } catch (error) {
        console.error('Erreur lors de la récupération des données:', error);
        setLoading(false);
      }
    };

    fetchpackData();
  }, []);

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (!data || data.length === 0) {
    return <Typography variant="h6">Aucune donnée trouvée</Typography>;
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppBar position="static">
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', padding: '8px' }}>
          <Button color="warning" onClick={() => navigate('/')}>
            Retour
          </Button>
          <Typography variant="h6" sx={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}>
            Notes Générées
          </Typography>
        </Toolbar>
      </AppBar>

      <Box sx={{ padding: 2 }}>
        {data.map(pack => (
          <Paper key={pack.pack_id} elevation={3} sx={{ padding: 2, marginBottom: 2 }}>
            <Typography variant="h6" gutterBottom>
              PACK {pack.pack_id} - Ajouté le {new Date(pack.created_at).toLocaleDateString('fr-FR')}
            </Typography>

            {/* Civilité, pays, date de naissance et domiciliation */}
            <Typography variant={isMobile ? 'body2' : 'subtitle1'}>
            <strong>Civilité :</strong> {pack.customers[0]?.identity[0]?.civilite || 'N.C.'} - {pack.customers[0]?.identity[0]?.country || 'N.C.'} - Né(e) en {pack.customers[0]?.identity[0]?.birthdate || 'N.C.'} - Domicilié : {pack.customers[0]?.tax[0]?.domiciliation || 'N.C.'}
            </Typography>

            {/* Identité, qualité et validité */}
            <Typography variant={isMobile ? 'body2' : 'subtitle1'}>
              <strong>Identité :</strong> {pack.customers[0]?.identity[0]?.type_doc || 'N.C.'} - {pack.customers[0]?.identity[0]?.pdf ? 'PDF' : 'PHOTO'} - {pack.customers[0]?.identity[0]?.qualite || 'N.C.'} - Validité {pack.customers[0]?.identity[0]?.expiry_date || 'N.C.'}
            </Typography>

            {/* Impôts avec année, revenu, situation, parts et PDF */}
            <Typography variant={isMobile ? 'body2' : 'subtitle1'}>
              <strong>Impôts :</strong> {pack.customers[0]?.tax[0]?.annee_impots || 'N.C.'} sur {pack.customers[0]?.tax[0]?.annee_impots - 1} - {pack.customers[0]?.tax[0]?.pdf ? 'PDF' : 'PHOTO'} - {pack.customers[0]?.tax[0]?.revenu || 'N.C.'}€ revenu fiscal de référence - {situationMapping[pack.customers[0]?.tax[0]?.situation] || 'N.C.'}, {pack.customers[0]?.tax[0]?.parts || 'N.C.'} parts
            </Typography>

            {/* Banque */}
            <Typography variant={isMobile ? 'body2' : 'subtitle1'}>
              <strong>Banque :</strong> {pack.customers[0]?.nom_banque || 'N.C.'}
            </Typography>

            {/* Fiche de paie avec PDF ou PHOTO */}
            {pack.customers[0]?.pay && pack.customers[0].pay.length > 0 ? (
              <Typography variant={isMobile ? 'body2' : 'subtitle1'}>
                <strong>Fiche de paie :</strong> {pack.customers[0]?.pay_pdf ? 'PDF' : 'PHOTO'} - {pack.customers[0].pay.map(p => p.salaire ? `${moisMapping[p.mois]} ${p.salaire}€` : null).filter(Boolean).join(', ')}
              </Typography>
            ) : ('')}


            {/* Commentaire */}
            <Typography variant={isMobile ? 'body2' : 'subtitle1'}>
            <strong>Commentaire :</strong> {pack.commentaire || 'Aucun'}
            </Typography>

            {/* Prix */}
            <Typography variant="h6" sx={{ mt: 1 }}>
              Prix : {(websiteAnon === '1' ? pack.price + 100 : websiteAnon === '2' ? pack.price + 80 : pack.price).toLocaleString('fr-FR', { style: 'currency', currency: 'EUR' })}
            </Typography>

            {/* Lien vers plus de détails */}
            <Typography variant={isMobile ? 'body2' : 'subtitle1'} sx={{ mt: 1 }}>
            <strong>Détails :</strong> <a href={`/view/${pack.pack_id}`} target="_blank" rel="noopener noreferrer">
                Voir plus de détails
              </a>
            </Typography>
          </Paper>
        ))}
      </Box>
    </ThemeProvider>
  );
};

export default GenerateNotes;
